.input-calendar {
  background: #3b3f50;
  color: white;
  border: 1px solid #3b3f50;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  border-radius: 4px;
}
.input-calendar > p {
  margin: 0;
  text-align: center;
  font-size: 12px;
}
.input-calendar span {
  color: #8c9099;
}

.calendar {
  background: var(--secondary-color);
  border: 0;
  border-radius: 4px solid;
  box-shadow: 0px 5px 20px rgba(6, 10, 14, 0.4);
}
.calendar .react-datepicker__current-month {
  color: white;
}
.calendar .react-datepicker__day-name {
  color: #8c9099 !important ;
}
.calendar .start-range {
  background-color: #f12c4c !important ;
  border-radius: 50% !important;
  position: relative;
  z-index: 4 !important;
}

.calendar .end-range {
  background-color: #f12c4c;
  border-radius: 50%;
}

.calendar .react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: #4e2e3c;
  color: #fff;
}
.react-datepicker__day--in-selecting-range {
  background: #4e2e3c !important;
}
/* .calendar .react-datepicker__day--selected,
.eact-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0;
} */

.calendar .react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  /* margin: 0.166rem 0; */
  color: #ffff;
}
.calendar .react-datepicker__header {
  background-color: var(--secondary-color);
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  background-color: #f12c4c;
  color: white;
}
.react-datepicker__triangle {
  display: none !important;
}

.react-datepicker__day--today {
  background: #464e56;
  border-radius: 50%;
}

.calendar-container {
  display: flex;
}

.calendar-presets {
  display: flex;
  margin: 0;
  padding: 0;
  flex-direction: column;
  justify-content: flex-start;
  width: 158px;
  padding-top: 16px;
  background: #0f1923;
}
.calendar-container li:first-child {
  color: #8c9099;
  font-size: 11px;
}
.calendar-container li {
  list-style: none;
  color: #ffffff;
  margin: 4px 12px;
  padding-left: 8px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
}

.calendar-container .li--select {
  background: rgba(37, 47, 56, 0.5);
  border: 1px solid #323b45;
}

.react-datepicker__month-container {
  padding-top: 10px !important;
  background-color: #0f1923;
}

@media (max-width: 1024px) {
  .calendar-presets {
    left: -60px;
    position: relative;
  }
}
