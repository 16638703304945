/* The goals of this file is to let the developer override any style used by the  react-super-responsive-table package*/
.responsiveTable {
  width: 100%;
  background-color: #131521;
}

.responsiveTable td .tdBefore {
  display: none;
}

@media screen and (max-width: 40em) {
  /*
      Force table elements to not behave like tables anymore
      Hide table headers (but not display: none;, for accessibility)
    */

  .responsiveTable table,
  .responsiveTable thead,
  .responsiveTable tbody,
  .responsiveTable th,
  .responsiveTable td,
  .responsiveTable tr {
    display: block;
  }

  .responsiveTable thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
    border-bottom: 2px solid #333;
  }

  .responsiveTable tbody tr {
    border: 1px solid #000;
    // padding: 0.25em;
    margin-bottom: 10px;
    background-color: #1d1e25;
  }

  .responsiveTable td.pivoted {
    /* Behave like a "row" */
    border: none !important;
    position: relative;
    padding-left: calc(50% + 10px) !important;
    // text-align: left !important;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    min-height: 38px;
  }

  .responsiveTable td .tdBefore {
    /* Now like a table header */
    position: absolute;
    display: block;

    /* Top/left values mimic padding */
    left: 1rem;
    width: calc(50% - 20px);
    white-space: pre-wrap;
    overflow-wrap: break-word;
    // text-align: left !important;
    font-weight: 600;
    font-size: 12px;
    text-align: right;
  }

  //   For stripped tables
  //   .responsiveTable td:nth-child(even) {
  //     background-color: #2d2f39;
  //   }
}
