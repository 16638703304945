.container-input-affiliate-link {
  padding-bottom: 2rem;
  padding: 1rem;
  border-radius: 5px;
}

.wrap-switch-affiliate {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.wrap-switch-affiliate > p {
  margin: 0;
  font-size: 16px;
}

.affiliate-link-flex input {
  margin: 0 !important;
}

@media (min-width: 768px) {
  .affiliate-link-flex {
    gap: 2rem;
    align-items: center;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
.affiliate-link-container {
  padding: 20px;
  height: 600px;
}

.affiliate-link-container > button {
  margin: auto;
  width: 160px;
  display: block;
  margin: auto;
  margin-top: 2rem;
}

.affiliate-translate {
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(350px, 1fr)); */
  gap: 2rem;
}

.affialiate-link-actions {
  margin: 0;
  padding: 0;
  display: flex;
  gap: 1rem;
  align-items: center;
}
.affialiate-link-actions li {
  list-style: none;
}
.update-affiliate-groups-select {
  margin-bottom: 2rem;
}

.translate-update {
}
.translate-update label {
  text-transform: capitalize;
}
.translate-update__lang p {
  margin: 0;
}
.translate-update__lang {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.update-affiliate-groups {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  margin-bottom: 2rem;
}
.update-affiliate-group-input {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
}
