

#promo .create {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    flex-direction: column;
}

#promo .create .Select {
    width: 500px;
    margin-bottom: 10px;
}