.inputGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inputField {
  padding: 10px;
}

.block {
  background-color: #262626;
  margin: 10px 0;
  width: 100%;
  border-radius: 4px;
}

.targetContainer {
  width: 100%;
  margin: 15px;
}
.buttonContainer {
  width: 100%;
  text-align: right;
  margin-top: 15px;
}

.announcementsSelect {
  margin-top: 10px;
  width: 25%;
}

@media (max-width: 1024px) {
  .inputGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .inputField {
    padding: 10px;
  }

  .block {
    background-color: #262626;
    margin: 10px 0;
    width: 100%;
    border-radius: 4px;
  }

  .announcementsSelect {
    margin-top: 10px;
    width: 90%;
  }
}
