.group-checkbox {
  display: inline-block;
  width: 100%;
  margin-bottom: 10px;
  margin: 20px 0;
}

.games-category-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 200px));
  gap: 10px;
  justify-content: center;
}

.group-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 30px;
}
.group-input > input {
  height: 48px;
  width: 100%;
  max-width: 350px;
}
.group-input > button {
  width: 100%;
  max-width: 350px;
}
