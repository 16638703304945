.form {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #64687c;
}
.editRoles {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  align-items: center;

  :global(.Select) {
    width: 450px;
    margin-right: 20px;
  }

  & button {
    padding: 9px 25px;
  }

  & input {
    margin-right: 20px;
    width: 10%;
  }

  // & .extraInput {
  //   width: 20%;
  // }
}

.actionButton {
  padding: 9px 25px;
}

.closeAccount {
  border: 1px solid #f9305b;
  background-color: #602c2c;
  padding: 25px;
  border-radius: 4px;
  text-align: right;
}

@media (max-width: 1024px) {
  .editRoles {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    height: 140px;

    // :global(.Select) {
    //   margin-bottom: 10px;
    // }

    & input {
      width: 100%;
      margin-right: 0;
    }

    // & .extraInput {
    //   width: 100%;
    //   margin-bottom: 10px;
    // }

    // & button {
    //   margin-bottom: 10px;
    // }
  }
}
