.ticketIA__container-textarea {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.ticketIA {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 20px 0;
}
.ticketIA__container {
  max-width: 992px;
  width: 100%;
}
.ticketIA__textarea {
  height: 150px;
}
.ticketIA__textarea--preview {
  height: 250px;
}
.ticketIA__select {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 0 10px;
  font-size: 16px;
  color: #333;
  background-color: #fff;
  outline: none;
  cursor: pointer;
}
.ticketIA__form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0;
}
