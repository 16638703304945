.affiliate-page.postback {
  display: flex;
  flex-direction: column;
  width: 40%;
  margin-left: 5px;
}

.affiliate-page__group-input {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
@media (min-width: 550px) {
  .affiliate-page__group-input {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
}
.affiliate-page__container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: auto;
  margin-bottom: 50px;
  max-width: 750px;
}
.affiliate-page__container > button {
  max-width: 350px;
  margin: auto;
  margin-top: 20px;
}
.affiliate-page__group-input > label {
  text-align: left;
}
.affiliate-page__group-input > select {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  padding: 0 10px;
  font-size: 14px;
  color: #3b3f50;
  background: #fff;
  outline: none;
}
.postback-button {
  margin-top: 10px;
  width: 50%;
}

.affiliate-page .nav-output {
  flex: 1;
  overflow-x: auto;
  margin-top: 13px;
}
.affiliate-page .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.affiliate-page .header img {
  width: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

.affiliate-page .header .wallet-select {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.affiliate-page .header .wallet-select .Select {
  width: 200px;
  margin-left: 12px;
}

.affiliate-page .header .wallet-select .Select .Select-clear {
  display: none;
}

.affiliate-page .header .user-header-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.affiliate-page nav {
  display: flex;
  flex-wrap: wrap;
}

.affiliate-page nav a {
  display: block;
  padding: 16px;
  font-size: 12px;
}
.affiliate-page nav a.active {
  border: none;
  border-bottom: 2px solid #f9305b;
}

.affiliate-page .header .stacked {
  display: flex;
  flex-direction: column;
}

.affiliate-page .user-role {
  display: flex;
  align-items: center;
}

.affiliate-page .user-role span {
  margin-right: 20px;
}

.user-roles-filter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-y: hidden;
  overflow-x: auto;
  margin: 0 15px;
}

.affiliate-page .changed {
  border: 2px solid rgba(27, 182, 127, 0.7);
}

.user-roles-filter .input-group {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-right: 25px;
}

.user-roles-filter .input-group > * {
  width: unset;
}

.affiliate-page .document {
  padding: 15px;
  cursor: pointer;
  border-radius: 4px;
  max-width: 200px;
  background: #3b3f50;
}

.affiliate-page .documents {
  display: flex;
}

.affiliate-page .balances {
  display: flex;
  gap: 4rem;
}
