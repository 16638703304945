@import url("https://use.typekit.net/mnu7cpv.css");

body,
html {
  margin: 0;
  padding: 0;
  background: #131521;
  color: #ffffff;
  font-size: 16px;
  line-height: 1.6;
}

.good {
  color: green;
}

.bad {
  color: red;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  outline: none;
}

button {
  border-radius: 4px;
  background-color: #f9305b;
  /* -webkit-box-shadow: 0 5px 10px 0 rgba(249, 48, 91, 0.43); */
  /* box-shadow: 0 5px 10px 0 rgba(249, 48, 91, 0.43); */
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  color: #ffffff;
  padding: 15px 25px;
  border: none;
}
button.small {
  font-size: 12px;
  padding: 7px 10px;
}

button.outlined {
  background-color: transparent;
  border: 1px solid #f9305b;
}

button:hover {
  box-shadow: 0 5px 17px 0 rgba(249, 48, 91, 0.5);
  cursor: pointer;
}

button:active {
  transform: translateY(2px);
}

button:disabled {
  opacity: 0.5;
}

button:active {
  outline: none;
}

table {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}

table tr {
  border-bottom: 2px solid #2c2c2d;
}

table th {
  font-size: 14px;
  padding: 10px 15px;
}

table td,
.ReactTable .rt-td {
  padding: 10px 15px;
  color: #7b8ca9;
  white-space: nowrap;
}

table td.user img {
  vertical-align: middle;
  border-radius: 50%;
  width: 20px;
  margin-right: 10px;
}

input,
textarea {
  background: #3b3f50;
  color: #ffffff;
  padding: 10px 15px;
  border-radius: 5px;
  border: none;
}

a {
  color: #97a8ff;
  cursor: pointer;
}

a:hover {
  text-decoration: underline;
}

hr {
  border-color: #2c2c2d;
  border-style: solid;
  margin: 25px 0;
}

.good {
  color: green;
}

.bad {
  color: red;
}

.content {
  margin: 0 auto;
  padding: 0 25px;
}

.page {
  background: #1d1e25;
  border-radius: 10px;
  padding: 25px;
  min-height: 50vh;
  overflow-x: auto;
  max-height: calc(100vh - 119px);
}
@media (max-width: 1024px) {
  .page {
    padding: 10px;
    max-height: 100vh;
    margin-bottom: 90px;
  }
}

.page h3 {
  margin-top: 0;
}

.input-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.input-group input:not([type="checkbox"]) {
  width: 100%;
}

input:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.input-group input {
  margin-bottom: 25px;
}

.input-group .input-row input {
  margin: 0;
  margin-left: 20px;
}
.input-group .input-row {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

#intercom-container {
  right: 50px;
}

.input-group > * {
  margin-bottom: 25px;
}

.input-group > * {
  width: 100%;
}

.esports-bets .esports-bet-slip {
  margin-bottom: 100px;
  text-align: left;
  border-radius: 4px;
  padding: 25px;
}

#user .esports-bets .esports-bet-slip h4 {
  text-transform: uppercase;
  margin: 0;
}

.esports-bets .esports-bet-slip table {
  table-layout: fixed;
  width: 100%;
  margin: 0;
}

.esports-bets .esports-bet-slip .top {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.esports-bets .esports-bet-slip .top .right {
  background: #2b2c37;
  text-transform: capitalize;
  padding: 10px 15px;
  font-size: 12px;
  border-radius: 4px;
  margin-left: 25px;
}

.esports-bets .esports-bet-slip table th {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.esports-bets .esports-bet-slip table td {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7b8ca9;
  border-bottom: none;
}

.esports-bets .esports-bet-slip table tr:not(:last-child) td {
  border-bottom: solid 2px #272b41;
}

.esports-bets .esports-bet-slip .top .right.status.win {
  background: #1bb67f;
}

.esports-bets .esports-bet-slip .top .right.status.loss {
  background: #5a3b3b;
}

.esports-bets .esports-bet-slip .top .right.status.refund {
  background: #b68d1b;
}

.Select input {
  color: #333;
}

.draggable-tags {
  display: flex;
  align-items: flex-start;
  min-height: 110px;
}

.draggable-tags .tag {
  padding: 15px;
  border-radius: 4px;
  background: #3b3f50;
}

.draggable-tags .tag i {
  margin-left: 15px;
  cursor: pointer;
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.ReactModal__Content {
}
.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td,
.ReactTable .rt-th,
.ReactTable .rt-td {
  overflow: visible !important;
  text-align: left;
}

.ReactTable .-pagination .-btn {
  background-color: #f9305b !important;
  color: #ffffff !important;
}

.mobileVersion {
  height: 100vh;
  display: flex;
  flex-wrap: initial;
  text-align: center;
  padding: 0 10px;
}

.mobileVersion:first-child {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sub-nav a.active {
  border-bottom: none;
}
.loading-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(29, 30, 37, 0.9);
  z-index: 2;
  animation: fade-in 800ms ease;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 100px;
  flex-direction: column;
}

.navigation-show {
  display: flex;
  justify-content: space-between;
}

.button-logout {
  margin-top: 20px;
  height: 45px;
  width: 100px;
  border-radius: 5px;
  background-color: #131521;
}

.button-logout:hover {
  box-shadow: none;
}
.navigation-rightside {
  display: flex;
}
.user-navigation {
  margin-top: 30px;
}
