nav.main-nav {
    display: flex;
    align-items: center;
    height: 67px;
    margin-bottom: 25px;
}


nav {
    margin-bottom: 50px;
}

nav a {
    padding: 10px 25px;
    line-height: 50px;
    color: #64687c;
    font-weight: bold;
    font-size: 14px;
}

nav a:hover {
    text-decoration: none;
}

nav a.active {
    border-bottom: 2px solid #f9305b;
    color: #ffffff;
}