.box-selects {
  justify-content: flex-start;
}

.box-selects-status {
  display: flex;
  flex-direction: row;

  @media (max-width: 760px) {
    flex-direction: column;
  }
}

.status-indicator {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.status-indicator.online {
  background-color: green;
  box-shadow: 0 0 10px 0 green, 0 0 5px 0 #00ff00, inset 0 0 5px green;
}

.status-indicator.offline {
  background-color: red;
  box-shadow: 0 0 10px 0 red, 0 0 5px 0 #ff0000, inset 0 0 5px red;
}

.status-indicator.disrupted {
  background-color: yellow;
  box-shadow: 0 0 10px 0 yellow, 0 0 5px 0 #ffff00, inset 0 0 5px yellow;
}