.main {
  :global {
    .Select {
      width: 25%;
    }
  }
}
.column {
  flex-basis: 25%;
  max-width: 25%;
}

.buttons {
  margin-bottom: 25px;
  & > button {
    margin-right: 25px;
  }
}

.section {
  display: flex;
}
@media (max-width: 1024px) {
  .summary {
    max-width: 100%;
    width: 100%;
    flex-basis: 100%;
  }

  .column {
    flex-basis: 100%;
    max-width: 100%;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    & > button {
      margin-right: 0;
    }
  }

  .main {
    :global {
      .Select {
        width: 100%;
      }
    }
  }
}
