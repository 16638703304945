.reporting-section {
    border: 1px solid #2c2c2d;
}
.reporting-section .header {
    border-bottom: 1px solid #2c2c2d;
    padding: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.reporting-section .body {
    padding: 25px;
    position: relative;
}
.reporting-section, .reporting-section .body {
    min-height: 500px;
}

.reporting-section .body h2 {
    text-align: center;
    font-weight: 500;
    margin-bottom: 25px;
}
@keyframes fade-in {
    from {opacity: 0}
    to {opacity: 1}
}


.loader {
    font-size: 10px;
    margin: 50px auto;
    text-indent: -9999em;
    width: 11em;
    height: 11em;
    border-radius: 50%;
    background: #ffffff;
    background: -moz-linear-gradient(left, #f9305b 10%, rgba(255, 255, 255, 0) 42%);
    background: -webkit-linear-gradient(left, #f9305b 10%, rgba(255, 255, 255, 0) 42%);
    background: -o-linear-gradient(left, #f9305b 10%, rgba(255, 255, 255, 0) 42%);
    background: -ms-linear-gradient(left, #f9305b 10%, rgba(255, 255, 255, 0) 42%);
    background: linear-gradient(to right, #f9305b 10%, rgba(255, 255, 255, 0) 42%);
    position: relative;
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}
.loader:before {
    width: 50%;
    height: 50%;
    background: #f9305b;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
}
.loader:after {
    background: #1d1e25;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
@-webkit-keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
