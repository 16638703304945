.wheels .items-select {
  padding: 15px;
  background: #16171b;
  display: flex;
  align-items: center;
  overflow: auto;
  flex-wrap: wrap;
  max-height: 500px;
}

.wheels .wheel-item img {
  max-height: 60px;
  max-width: 60px;
}

.wheels .wheel-item .value {
  color: green;
}

.wheels .wheel-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px 15px;
  flex-basis: 25%;
  max-width: 25%;
  cursor: pointer;
}

.filters-query {
  display: flex;
  margin-bottom: 50px;
}
.filter-query {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 10px 25px;
}

.rewards-search-row {
  background: #131521;
  padding: 15px;
  border-radius: 15px;
  height: 78px;
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 80%;
  margin-left: 10%;
}

.rewards-search-row input {
    width: 100%;
}

.rewards-wrapper-container {
  width: 100%;
  padding: 0 30px;
}