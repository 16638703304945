.timezone-select-container {
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
}

.timezone-select-input {
  display: flex;
  grid-gap: 10px;
  gap: 20px;
  width: fit-content;
  padding: 10px;
  margin: 0 0 10px;
  background: black;
  align-items: center;
  justify-content: center;
}

.timezone-select-input select {
  height: 30px;
  padding: 0 20px;
  cursor: pointer;
  border-radius: 5px;
}
