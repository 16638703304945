.globalXp-group-inputs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin: 1rem 0;
  gap: 10px;
}
.globalXp-group-inputs > label {
  text-align: left;
}
.globalXp-section {
  margin: 1rem 0;
  max-width: 550px;
}

.globalXp-group-btn {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin-top: 50px;
}
.globalXp-title {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
}

.globalXp-container {
  padding: 20px;
}
