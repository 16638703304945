.panel {
  padding-bottom: 10px;
  border-bottom: 1px solid #64687c;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  flex-direction: row;
}

.select_gender {
  background: #3b3f50;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  border: none;
  width: 100%;
}

.titlePanel {
  text-align: left;
  width: 100%;
}

.form {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}

.formSelect {
  composes: form;
}

.field {
  height: 100px;
  width: 250px;
  padding: 5px;
  text-align: left;
  margin-right: 24px;
}

.message {
  font-size: 12px;
  font-style: italic;
  color: red;
  text-align: right;
}
.input {
  & input {
    width: 100%;
  }
}

.buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;

  & button {
    margin: 10px;
  }
}

.stat {
  border-radius: 4px;
  border: 1px solid #64687c;
  padding: 15px;
  margin: 5px;
  min-width: 160px;
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  justify-content: center;
  align-items: flex-start;
}

.singleInput {
  width: 30%;
}

@media (max-width: 1024px) {
  .buttons {
    flex-direction: column;
    width: 100%;
  }
  .panel {
    justify-content: space-between;
  }
  .field {
    width: 95%;
  }
  .formSelect {
    width: 100%;
  }
  .form {
    justify-content: center;
  }
  .field {
    margin-right: 0;
  }
  .singleInput {
    width: 100%;
  }
}
