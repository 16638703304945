.category-select {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.view-category__new-category {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 50px;
}
.category-page__game {
  margin: 20px 0;
}
.category-select .category-box {
  background: #131521;
  padding: 4px 12px;
  cursor: pointer;
  border-radius: 4px;
}

.category-select .category-box:hover,
.category-select .category-box.selected {
  background: #232639;
}
.category-page {
  padding: 20px;
}
.category-page .games > div {
  margin-bottom: 12px;
}

.game-box {
  background: #131521;

  padding: 12px;
  overflow-wrap: break-word;
  height: 100%;
  border-radius: 4px;
  cursor: pointer;

  position: relative;
}

.game-box .remove {
  position: absolute;
  top: 5px;
  right: 5px;
}

.game-box .remove:hover {
  color: red;
}

.game-box:hover {
  background: #232639;
}

.game-box p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  margin: 0;
}

.game-box img {
  width: 100%;
  overflow: hidden;
  float: left;
}

.game-box.inCategory {
  border: 2px solid #006d3f;
}

.game-box input {
  width: 100%;
}

.game-box .buttons {
  display: flex;
  margin: 10px 0;
}

.game-box .buttons button {
  padding: 2px;
  width: 100%;
  font-size: 14px;
}
