.label-challenge {
  font-size: 16px;
}

.column {
  min-width: 200px;
}
.form-challenge {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.currency-rates-container {
  > table {
    margin-top: 15px;
  }
}

.create-challenge-button {
  margin-top: 20px;
  margin-left: 30px;
  width: 200px;
  height: 50px;
  font-size: 16px;
  margin-bottom: 50px;
}
.rewardSelect {
  width: 500px;
}
.time-challenges {
  margin-left: 20px;
  font-size: 18px;
  margin-top: 10px;
}
.column-rewardSelect {
  margin-top: 20px;
  width: 200px;
}

.margin-left-form-challenge {
  padding: 0 10px;
}
.row-challenge {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(auto, 1fr));
  gap: 20px;
}

@media (max-width: 750px) {
  .rewardSelect {
    width: 300px;
  }
}
.featured-input {
  width: 15px;
  height: 15px;
  margin-left: 20px;
  margin-top: 17px;
}
.column.featured-column {
  margin-top: 20px;
}
.recurring-check-label {
  display: flex;
}
.totalprize-box {
  display: flex;
  flex-direction: column;
}
