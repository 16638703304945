.waitlistdetail h1 {
  color: #fff;
  text-transform: capitalize;
}

.waitlistdetail__header {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.waitlistdetail__header form {
  display: flex;
  align-items: center;
  gap: 1rem;
}
