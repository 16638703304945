.pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 15px
}
.state {
    margin-right: 4px;
    color: #77889A
}
.page {
    animation: 200ms ease;
}
.totalPages {

}
.button {
    width: 32px;
    height: 32px;
    background: #252F38;
    border-radius: 4px;
    border: 1px solid #323B45;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 12px;
    animation: 200ms ease;
}