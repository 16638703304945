.user-page .nav-output {
  flex: 1;
  overflow-x: auto;
  margin-top: 13px;
}
.user-page .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.user-page .header img {
  width: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

.user-page .header .wallet-select {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.user-page .header .wallet-select .Select {
  width: 200px;
  margin-left: 12px;
}

.user-page .header .wallet-select .Select .Select-clear {
  display: none;
}

.user-page .header .user-header-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.user-page nav {
  display: flex;
  flex-wrap: wrap;
}

.user-page nav a {
  display: block;
  padding: 16px;
  font-size: 12px;
}
.user-page nav a.active {
  border: none;
  border-bottom: 2px solid #f9305b;
}

.user-page .header .stacked {
  display: flex;
  flex-direction: column;
}

.user-page .user-role {
  display: flex;
  align-items: center;
}

.user-page .user-role span {
  margin-right: 20px;
}

.user-roles-filter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-y: hidden;
  overflow-x: auto;
  margin: 0 15px;
}

.user-page .changed {
  border: 2px solid rgba(27, 182, 127, 0.7);
}

.user-roles-filter .input-group {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-right: 25px;
}

.user-roles-filter .input-group > * {
  width: unset;
}

.user-page .document {
  padding: 15px;
  cursor: pointer;
  border-radius: 4px;
  max-width: 200px;
  background: #3b3f50;
}

.user-page .documents {
  display: flex;
}

.user-page .stat {
  margin-bottom: 50px;
}

.user-page .stat h3 {
  text-transform: uppercase;
  font-size: 14px;
}

.user-page .stat h4 {
  font-weight: 400;
}

.document-modal .close {
  font-size: 20px;
  position: relative;
  right: 0;
  color: red;
  margin-right: 0px;
  cursor: pointer;
  text-align: right;
}

.document-modal.upload-modal > div {
  background: #1b2430 !important;
}

#kyc-emails .tabbed-nav {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;
}

#kyc-emails .tabbed-nav > div {
  padding: 5px 20px;
  cursor: pointer;
}

#kyc-emails .tabbed-nav > div.selected {
  border-bottom: 2px solid #f9305b;
}

#kyc-emails .kyc-created-email .inputs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 14px;
}

#kyc-emails .kyc-created-email .inputs > div {
  margin-right: 14px;
  display: flex;
  align-items: center;
}

#kyc-emails .kyc-created-email .inputs > div label {
  margin-right: 10px;
}

#kyc-emails .declined-reasons {
  margin-left: 30px;
}

#kyc-emails .Select {
  width: 300px;
  margin: 14px 0;
}

#kyc-emails button {
  margin: 14px 0;
}

.user-page .accounting-section {
  border: 1px solid #2c2c2d;
  border-radius: 25px;
  padding: 25px;
}

.user-page .accounting-section .accounting-section-row {
  padding: 25px;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #7b8ca9;
}

.user-page .accounting-section .accounting-section-row:not(:last-child) {
  border-bottom: 1px solid #2c2c2d;
}
.user-page .summary-row {
  line-height: 3;

  margin: 0 28px;
}
.user-page .summary-row .line-item {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}

@media (max-width: 1024px) {
  .user-page .nav-output {
    overflow-x: hidden;
  }
  #kyc-emails .Select {
    width: auto;
  }
}
