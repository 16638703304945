.EZDrawer {
  .EZDrawer__checkbox {
    display: none;

    &:checked {
      & ~ .EZDrawer__overlay {
        display: block;
        opacity: 1;
      }

      & ~ .EZDrawer__container {
        visibility: visible;
        transform: translate3d(0, 0, 0) !important;
      }
    }
  }

  .EZDrawer__overlay {
    display: none;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
  }

  .EZDrawer__container {
    position: fixed;
    visibility: hidden;
    background: #131521;
    transition: all;
    box-shadow: 0 0 10px 5px rgba($color: #000000, $alpha: 0.1);
  }
}
