

.settings {
    display: flex;
}

.settings .input-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    cursor: pointer;
}

.settings .input-group  > * {
    width: auto;
    margin-bottom: 0;
    margin-right: 5px;
}

.user-search-row {
    background: #131521;
    padding: 15px;
    border-radius: 15px;
    height: 78px;
    display: flex;
}
.user-search-row input {
    flex: 1;
}
.user-search-row input, .user-search-row button {
    height: 100%;
}
.user-finder {
    min-height: 500px;
    position: relative;
}
