.filtersQuery {
  display: flex;
  margin-bottom: 50px;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 50px;
}
.filterQuery {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  padding: 0 10px 25px;
  align-content: stretch;
  justify-content: flex-start;
  align-items: center;
  width: 25%;
  flex-wrap: wrap;

  & > h5 {
    line-height: 20px;
  }

  & > input {
    width: 100%;
  }

  :global(.Select) {
    width: 100%;
  }
}

.buttonExport {
  min-height: 24px;
  padding: 5px;
  font-weight: 600;
  font-size: 14px;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
  align-content: center;

  > button {
    margin: 10px 5px;
  }
}

@media (max-width: 1024px) {
  .filtersQuery {
    display: flex;
    margin-bottom: 50px;
    flex-direction: column;
    align-content: stretch;
    justify-content: center;
    align-items: stretch;
    margin-bottom: 50px;
  }
  .filterQuery {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    padding: 0 10px 25px;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: stretch;
    background-color: none;
    width: 100%;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    align-content: center;
  }
}
