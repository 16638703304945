.tabbedNav {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;

  & > div {
    padding: 5px 20px;
    cursor: pointer;

    &.selected {
      border-bottom: 2px solid #f9305b;
    }
    &:not(.selected) {
      color: #6f7393;
    }
  }
}

.inputs {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 14px;
}

@media (max-width: 1024px) {
  .tabbedNav {
    justify-content: space-evenly;
  }

  .inputs {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    align-content: flex-start;
    flex-direction: column;
  }
}

.selected {
  font-weight: bold;
}
