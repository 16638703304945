.parentPath {
  color: rgb(128, 128, 128);
  color: #f9305b;
  font-size: 12px;
  text-align: left;

  &:after {
    content: " > ";
    color: rgb(128, 128, 128);
  }
  &:last-child:after {
    content: "";
  }
}

.toggleMenu {
  // color: #f9305b;
  // font-size: 12px;
  // font-style: italic;
  // position: relative;
  // left: -10px;
  width: 24px;
}

.topDivider {
  border-top: 2px solid #64687c;
}

.item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  border-bottom: 1px solid #323b45;

  & > a {
    padding: 10px;
  }
}

.icon {
  margin-right: 10px;
}
