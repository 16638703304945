.race-create__row {
  border: 1px solid #d3d3;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
  margin: 15px 0;
  text-align: left;
}

.race-create__row_no_box {
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
  margin: 15px 0;
  text-align: left;
}

.create-race {
  padding: 15px;
  gap: 15px;
}

.race-create__check {
  display: flex;
  align-items: center;
  gap: 15px;

  input {
    width: 30px;
    height: 30px;
  }
}

.race-create__prize {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.race-create-button {
  margin-left: 15px;
}

.race-row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(auto, 1fr));
  gap: 20px;
}

.label-race {
  font-size: 16px;
}

.games-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 50%;
}

.games-container button+button {
  margin-left: 10px;
}

.games-container button {
  max-width: 160px;
}

.games-container h2 {
  margin: 5px 0;
}

.selected-games-container {
  background-color: #3b3f50;
  padding: 10px;
  border: 1px solid #d3d3;
  border-radius: 4px;
  min-width: 50%;
}