.presetLoad {
    margin-bottom: 20px;
    display: flex;
    gap: 20px;
    align-items: flex-start;
    flex-direction: column;
}

.presetLoad__info {
    max-width: 550px;
    margin-bottom: 20px;
}

.presetLoad__info>p {
    margin: 0;
    color: #dadada;
}

.presetLoad__select {
    height: 48px;
    border-radius: 4px;
}

.presetLoad__btn {
    display: flex;
    align-items: center;
    gap: 10px;
}

@media (min-width:768px) {
    .presetLoad {
        flex-direction: row;
    }
}