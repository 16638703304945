.user_stuck {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #f9305b;
    border-radius: 4px;
    margin-bottom: 20px;
    overflow: hidden;
}

.user_stuck_title {
    display: inline;
}

.user_stuck_container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    padding: 0 25px 25px;
    height: 100%;
    
    @media screen and (max-width: 800px) {
        grid-template-columns: 1fr;
    }
}

.user_stuck_action_button {
    margin-top: 20px;
}

.user_stuck_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 25px 20px;
    cursor: pointer;
}

.user_stuck_collapse_icon {
    transform: rotate(90deg);
}

.user_stuck_open .user_stuck_collapse_icon {
    transform: rotate(270deg);
}