.container {
  width: 100%;
  margin-bottom: 15px;
}
.contentEntry {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 15px;

  & h4 {
    padding: 0 20px;
    width: 200px;
  }

  & textarea {
    width: 50%;
    flex-grow: 1;
    resize: none;
    min-height: 80px;
  }

  & .textAreaLocale {
    width: 25%;
  }
  & .textAreaContent {
    width: 75%;
  }

  .Select {
    flex-shrink: 0;
    width: 200px;
    margin-right: 20px;
  }
}

.translatorWrap {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  & button {
    width: 160px;
  }
}

@media (max-width: 1024px) {
  .contentEntry {
  }

  .container {
    width: 100%;
    margin-bottom: 15px;
    padding-top: 15px;
  }
  .contentEntry {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;
    flex-direction: column;
    align-content: center;
    margin-bottom: 15px;

    & h4 {
      padding: 0 20px;
      width: 200px;
    }

    & textarea {
      min-height: 120px;
    }

    & .textAreaLocale,
    .textAreaContent {
      width: 100%;
    }
    // & .textAreaContent {
    //   width: 100%;
    // }

    .Select {
      flex-shrink: 0;
      width: 200px;
      margin-right: 20px;
    }
  }

  .translatorWrap {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    & button {
      width: 160px;
    }
  }
}
