.ul {
  list-style-type: none;
  padding: 0;
}

.icon {
  margin-left: 10px;
}

.burger {
  background-color: #f9305b;
  padding: 8px;
  position: fixed;
  border-radius: 5px;
  bottom: 20px;
  left: 20px;
  z-index: 4;
}
// .goToTop {
//   composes: burger;
//   right: 20px;
//   left: auto;
// }

.search {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
  background-color: #3b3f50;
  border-radius: 4px;

  & input {
    width: 90%;

    &:focus-visible {
      outline: none;
    }
  }

  & span {
    display: inline-block;
    background-color: #fff;
    color: #000;
    border-radius: 50%;
    font-size: 18px;
    line-height: 24px; /* set to same size as width, height */
    width: 24px;
    height: 24px;
    text-align: center;
    margin: 0 10px;
  }
}

.drawer {
  overflow-y: auto;
}
