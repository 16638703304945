.label-challenge {
  font-size: 16px;
}

.column {
  margin-left: 20px;
}

.create-challenge-button {
  margin-top: 20px;
  margin-left: 30px;
  width: 200px;
  height: 50px;
  font-size: 16px;
  margin-bottom: 50px;
}
.rewardSelect {
  width: 500px;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  justify-content: center;
  margin: 24px 0;
}

.btn-pagination {
  background: #f9305b;
  color: #fff;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  margin-left: 10px;
  height: 25px;
}
.pagination_page {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #6f7393;
  cursor: pointer;
  font-size: 12px;
}

.pagination_total {
  margin-left: auto;
  width: fit-content;
  margin-top: 10px;
  margin-right: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #6f7393;
  cursor: pointer;
  font-size: 12px;
}
.column-rewardSelect {
  margin-top: 20px;
  width: 200px;
}

.margin-left-form-challenge {
}
.row-challenge {
  display: flex;
  flex-direction: row;
}
.text-center {
  text-align: center;
}
.pr-30 {
  padding-right: 30px;
}
.mr-20 {
  margin-right: 20px;
}
.between {
  justify-content: space-between;
}

.amount-deviation-container {
  background-color: #f9305b;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  color: #ddd;
  padding: 5px 15px 5px 15px;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.amount-deviation {
  font-size: 18px;
}

@media (max-width: 750px) {
  .row-challenge {
    display: flex;
    flex-direction: column;
  }
  .rewardSelect {
    width: 300px;
  }
}
.box-select {
  width: 300px;
  margin-left: 30px;
}
.box-selects {
  display: flex;
  justify-content: flex-end;
}

.text-box-select {
  font-size: 16px;
}
