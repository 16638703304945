
.bet-page .bet {
    background: #191b22;
}

.bet-page .bet .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
}

.bet-page .bet .top .left {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.bet-page .bet .top .right {
    display: flex;
    align-items: flex-end;
}
.bet-page .bet .stats {
    font-size: 12px;
}
.bet-page .bet .maximize {
    color: greenyellow;
    margin-left: 25px;
    cursor: pointer;
}
.bet-page .bet-journal-entries .journal-entries {
    border-bottom: none
}

.bet-page .event-stream .payload {
    font-size: 14px;
}

.bet-page .bet-data {
    padding: 15px;
}