.container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
}

.button-container{ 
 display: flex;
 justify-content: flex-end;

}

.container label {
  padding-right: 1rem;
}

.file-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.file-input-container{
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 10px
}