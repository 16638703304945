.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-around;
  align-items: center;
}

.field {
  width: 100%;
  margin: 10px 0;
  text-align: left;

  & label {
    margin-bottom: 5px;
  }
}

@media (min-width: 1024px) {
  .container {
    flex-wrap: nowrap;
  }
  .field {
    width: 30%;
  }
}
