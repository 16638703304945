.input-row {
    padding: 15px;
    background: #131521;
    display: flex;
}

.input-row>input {
    flex: 1;
    margin: 0 10px;
}

.game-page .tags {
    display: flex;

    flex-wrap: wrap;
}

.game-page .tag {
    background: #131521;
    padding: 5px;
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 12px;
    display: flex;
    width: 200px;
    justify-content: space-between;
}

.game-page .tag .plus,
.game-page .tag .minus {
    padding: 0 10px;
    cursor: pointer;
    background: black;
    margin-left: 10px;
    border-radius: 6px;
}

.game-page .plus {
    color: springgreen;
}

.game-page .minus {
    color: orangered;
}

.blocked-countries {
    display: flex;
    gap: 10px;
    width: 100%;
}

.blocked-countries input {
    min-width: 500px;
}