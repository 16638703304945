@media (max-width: 1024px) {
  .info {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-around;
    align-items: center;
    width: 100%;
  }
}
