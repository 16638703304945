.check-cpf-container {
  display: flex;
  flex-direction: column;
}

label {
  font-weight: bold;
}

.key-container div {
  width: 100%;
}

.check-cpf-container .cpf-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}

.cpf-input button {
  width: 200px;
}

table.paleBlueRows {
  font-family: "Times New Roman", Times, serif;
  border: 1px solid #ffffff;
  text-align: center;
  border-collapse: collapse;
}

table.paleBlueRows td,
table.paleBlueRows th {
  border: 1px solid #ffffff;
  padding: 3px 2px;
}

table.paleBlueRows tbody td {
  font-size: 16px !important;
  background-color: transparent;
}

table.paleBlueRows tr:nth-child(even) {
  background: #d0e4f5;
}

table.paleBlueRows thead {
  background: #0b6fa4;
  border-bottom: 5px solid #ffffff;
}

table.paleBlueRows thead th {
  font-size: 16px !important;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  border-left: 2px solid #ffffff;
}

table.paleBlueRows thead th:first-child {
  border-left: none;
}

table.paleBlueRows tfoot {
  font-size: 16px !important;
  font-weight: bold;
  color: #333333;
  background: #d0e4f5;
  border-top: 3px solid #444444;
}

table.paleBlueRows tfoot td {
  font-size: 16px !important;
}
