.withdrawal-page section {
    background: #131521;
    border-radius: 15px;
    padding: 15px;
    margin-bottom: 50px;
}

.tier-button {
    color: #ffffff;
    border-bottom: 2px solid transparent;

    padding: 25px;
    font-weight: bold;
    font-size: 14px;
    background: transparent;
    border-radius: 0;
}

.tier-button--active{
    border-bottom: 2px solid #f9305b;
}

.tabs-tier {
    display: flex;
    gap:20px;
}

.withdrawal-page section .line-item {
    border-bottom: 2px solid #2c2c2d;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.withdrawal-page section .line-item.kyc-level .Select {
    width: 100%;
    margin: 0 10px;
}

.withdrawal-page section .line-item.kyc-level .label {
    white-space: nowrap;
}

.withdrawal-page section .line-item > .refund {
    width: 100%;
}

.withdrawal-page section .line-item button.small {
    font-size: 10px;
}

.withdrawal-page section .line-item.red {
    color: #f12c4c;
}

.withdrawal-page .row>* {
    padding: 0 45px;
}

.withdrawal-page .mutuals .row > div {
    max-height: 500px;
    overflow-y: auto;
}

.withdrawal-page section .line-item .label {
    font-size: 12px;
    text-transform: uppercase;
}
.withdrawal-page section .line-item .value {
    font-size: 12px;
}

.withdrawal-page h2 {
    font-size: 20px;
    font-weight: 500;
}

.withdrawal-page {
    position: relative;
}


.pix-controller {

    display: flex;
    padding: 16px 0;
}

.pix-controller button {
    height: 40px;
    padding: 0 15px;
    margin-right: 16px;
}

.withdrawal-page .user-page .accounts {
    overflow-x: auto;
}

.automated-withdrawals span.separator {
    margin: 0 5px;
    border-right: 2px solid rgba(255, 255, 255, 0.3)
}

input.automated-input {
    width: 100%;
}