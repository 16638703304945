.pagination {
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding: 0;
}



.pagination li {
    padding: 0 5px;
}

.pagination li:first-child {
    padding-left: 0;
    flex: 1;
    text-align: left;
}

.pagination li:last-child {
    padding-right: 0;
    flex: 1;
    text-align: right;
}

.pagination li {
    cursor: pointer;
}

.pagination li.selected {
    text-decoration: underline;
    font-weight: bold;
}

.cursor-pagination {
    display: flex;
    justify-content: space-between;
}
.cursor-pagination .back, .cursor-pagination .forward {
    cursor: pointer;
}
.cursor-pagination .back.disabled, .cursor-pagination .forward.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}