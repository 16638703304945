.affiliates-search-row {
  background: #131521;
  padding: 15px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.affiliates-search-row input {
  flex: 1;
}

.affiliates-search-row input,
.affiliates-search-row button {
  height: 100%;
}

.affiliates-finder {
  min-height: 500px;
  position: relative;
}

@media (min-width: 550px) {
  .affiliates-search-row {
    display: flex;
    flex-direction: row;
  }
}
